<template>
  <div class="video_wrap">
    <div class="content">
      <div @click="createClient">进入房间</div>
      <div @click="logoutHandler">离开房间</div>
      <!-- 远端视频 -->
      <div
        v-for="(item, index) in remoteStreamList"
        :key="index"
        class="remote-stream"
      >
        <div v-html="item.view" :class="{ 'distant-stream': item.view }"></div>
      </div>
      <!-- 本地视频 -->
      <div id="local_stream" class="local-stream"></div>
    </div>
  </div>
</template>
<script>
import LibGenerateTestUserSig from '@/utils/lib-generate-test-usersig.min.js';
import TRTC from 'trtc-js-sdk';

export default {
  name: 'ceshi',
  data() {
    return {
      SDKAPPIDConfig: '1400726497',
      SECRETKEYConfig:
        '651734042c84a454c57d1020b9db353c933e7fb68c9f1ccfe368df5e96fd8391',
      userId: '8888',
      roomId: null,
      client: '', // 客户端服务
      remoteStream: '', // 远方播放流
      localStream: '', // 本地流
      remoteStreamList: [], // 远端小视频
    };
  },
  methods: {
    clickk() {
      console.log(this.remoteStreamList, 'remoteStreamList');
    },
    // 可以绑定在按钮上，点击按钮，进行视频通话
    createClient(roomId) {
      this.userId = `web_${parseInt(Math.random() * 100000000)}`;
      this.roomId =  '888';
      console.log(this.roomId, 'this.roomId');
      this.localStream = '';
      this.remoteStreamList = [];
      // 获取签名
      const config = this.genTestUserSig(this.userId);
      const { sdkAppId } = config;
      const { userSig } = config;
      const { userId } = this;
      this.client = TRTC.createClient({
        mode: 'rtc', // 实时音视频通话
        sdkAppId,
        userId,
        userSig,
        useStringRoomId: true,
      });
      console.log(this.client, 'this.client');
      // 注册远程监听
      this.subscribeStream(this.client);
      const cc =  this.subscribeStream(this.client);
      console.log(cc, 'cc');
      // 注册监听事件 如，房间关闭
      this.handleEvents(this.client);
      // 初始化成功后才能加入房间
      this.joinRoom(this.client, this.roomId);
    },

    // 订阅远端流--加入房间之前
    subscribeStream(client) {
      this.client.on('stream-added', (event) => {
        const remoteStream = event.stream;
        console.log(`远端流增加: ${remoteStream.getId()}`);
        // 订阅远端流
        client.subscribe(remoteStream);
      });
    },

    handleEvents(client) {
      this.client.on('stream-subscribed', (event) => {
        const remoteStream = event.stream;
        console.log(`远端流订阅成功：${remoteStream.getId()}`);
        // 创建远端流标签，因为id是动态的，所以动态创建，用了v-html
        const remoteStreamItem = `<view id="${`remote_stream-${remoteStream.getId()}`}"  ></view>`;
        const isExist = this.remoteStreamList.find(item => item.userId === remoteStream.getUserId());
        if (!isExist) {
          this.remoteStreamList.push({
            id: remoteStream.getId(),
            userId: remoteStream.getUserId(),
            view: remoteStreamItem,
            hasVideo: true, // 是否开启了摄像头
            hasMic: true, // 是否开启了麦克风
          });
          console.log(this.remoteStreamList, 'remoteStreamList');
          // 做了dom操作 需要使用$nextTick(),否则找不到创建的标签无法进行播放
          this.$nextTick(() => {
            // 播放远端流
            remoteStream.play(`remote_stream-${remoteStream.getId()}`);
          });
        }
      });
      console.log(this.remoteStreamList, 'remoteStreamList');
    },

    // 加入房间
    joinRoom(client, roomId) {
      console.log(roomId, 'roomId');
      client
        .join({ roomId })
        .catch((error) => {
          console.error(`进房失败 ${error}`);
        })
        .then(() => {
          console.log('进房成功');
          // 创建本地流
          this.createStream(this.userId);
          console.log();
        });
    },
    // 创建本地音频流
    async createStream(userId) {
      const localStream = TRTC.createStream({
        userId,
        audio: true,
        video: true,
      });
      // 设置视频属性 Profile 为 '480p'
      localStream.setVideoProfile('1080p');

      this.localStream = localStream;

      try {
        await localStream
          .initialize()
          // .catch(error => {
          //   console.error('初始化本地流失败 ' + error)
          // })
          .then(() => {
            console.log('初始化本地流成功');
            // 创建好后才能播放 本地流播放 local_stream 是div的id
            localStream.play('local_stream');
            // 创建好后才能发布
            this.publishStream(localStream, this.client);
          });
      } catch (error) {
        switch (error.name) {
          case 'NotReadableError':
            alert('暂时无法访问摄像头/麦克风，请确保系统允许当前浏览器访问摄像头/麦克风，并且没有其他应用占用摄像头/麦克风');
            break;
          case 'NotAllowedError':
            if (error.message === 'Permission denied by system') {
              alert('请确保系统允许当前浏览器访问摄像头/麦克风');
            } else {
              console.log('User refused to share the screen');
              alert('请确保系统允许当前浏览器访问摄像头/麦克风');
            }
            break;
          case 'NotFoundError':
            alert('浏览器获取不到摄像头/麦克风设备，请检查设备连接并且确保系统允许当前浏览器访问摄像头/麦克风');
            break;
          default:
            break;
        }
      }
    },
    // 发布本地音视频流
    publishStream(localStream, client) {
      client
        .publish(localStream)
        .catch((error) => {
          console.error(`本地流发布失败 ${error}`);
        })
        .then(() => {
          console.log('本地流发布成功');
        });
    },

    // 退出房间
    logoutHandler() {
      this.leaveRoom(this.client);
    },
    leaveRoom(client) {
      client
        .leave()
        .then(() => {
          console.log('退房成功');
          // 停止本地流，关闭本地流内部的音视频播放器
          this.localStream.stop();
          // 关闭本地流，释放摄像头和麦克风访问权限
          this.localStream.close();
          this.localStream = null;
          this.client = null;
          // 退房成功，可再次调用client.join重新进房开启新的通话。
        })
        .catch((error) => {
          console.error(`退房失败 ${error}`);
          // 错误不可恢复，需要刷新页面。
        });
    },

    // 获取用户签名 - 前端测试使用
    genTestUserSig(userID) {
      /**
       * 腾讯云 SDKAppId，需要替换为您自己账号下的 SDKAppId。
       *
       * 进入腾讯云实时音视频[控制台](https://console.cloud.tencent.com/rav ) 创建应用，即可看到 SDKAppId，
       * 它是腾讯云用于区分客户的唯一标识。
       */
      const SDKAPPID = 1400726497;
      /**
       * 签名过期时间，建议不要设置的过短
       * <p>
       * 时间单位：秒
       * 默认时间：7 x 24 x 60 x 60 = 604800 = 7 天
       */
      const EXPIRETIME = 604800;
      /**
       * 计算签名用的加密密钥，获取步骤如下：
       *
       * step1. 进入腾讯云实时音视频[控制台](https://console.cloud.tencent.com/rav )，如果还没有应用就创建一个，
       * step2. 单击“应用配置”进入基础配置页面，并进一步找到“帐号体系集成”部分。
       * step3. 点击“查看密钥”按钮，就可以看到计算 UserSig 使用的加密的密钥了，请将其拷贝并复制到如下的变量中
       *
       * 注意：该方案仅适用于调试Demo，正式上线前请将 UserSig 计算代码和密钥迁移到您的后台服务器上，以避免加密密钥泄露导致的流量盗用。
       * 文档：https://cloud.tencent.com/document/product/647/17275#Server
       */
      const SECRETKEY = '651734042c84a454c57d1020b9db353c933e7fb68c9f1ccfe368df5e96fd8391';
      if (SDKAPPID === '' || SECRETKEY === '') {
        alert('请先配置好您的账号信息： SDKAPPID 及 SECRETKEY '
            + '\r\n\r\nPlease configure your SDKAPPID/SECRETKEY in js/debug/GenerateTestUserSig.js');
      }
      const generator = new LibGenerateTestUserSig(
        SDKAPPID,
        SECRETKEY,
        EXPIRETIME,
      );
      const userSig = generator.genTestUserSig(userID);
      return {
        sdkAppId: SDKAPPID,
        userSig,
      };
    },
  },
};
</script>
<style >
.local-stream {
  width: 500px;
  height: 500px;
  background-color: aqua;
}

.remote-stream {
  width: 200px;
  height: 200px;
  background-color: pink;
}
</style>
